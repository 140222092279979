import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import TransitionLink from 'gatsby-plugin-transition-link'
import React, { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import Slick from "react-slick"
import "slick-carousel/slick/slick.css"
import Formularz from '../../components/formularz/formularz'
import Intro from '../../components/projekty/comp/projekt_intro'
import Inne from '../../components/projekty/inne'
import Ocena from '../../components/projekty/OcenaProjektu'
import Strategia from '../../components/projekty/volley/1'
import Identyfikacja from '../../components/projekty/volley/2'
import Print from '../../components/projekty/volley/3'
import WebDesign from '../../components/projekty/volley/4'
import Digital from '../../components/projekty/volley/5'
import Social from '../../components/projekty/volley/6'
import Fotografia from '../../components/projekty/volley/7'
import Seo from '../../components/seo.js'
import Slider from '../../components/slider/slider'
import back from '../../images/ikony/goback.svg'
import oko from '../../images/ikony/oko.svg'
import paluch from '../../images/ikony/paluch.svg'
import strzalka from '../../images/ikony/strzalka.svg'
import style from './projekt.module.css'






let title = "VOLLEY WROCŁAW"

export default function Betard() {


    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/volley/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                        
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                okladka2: file(relativePath: { eq: "projekty/volley/okladka2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            
                        ...GatsbyImageSharpFluid
                        }
                    }
                    },
                         
            }
            `)

    // polyfill();
    const slider = useRef(null)
    const wrapper = useRef(null)

    const r1 = useRef(null)
    const r2 = useRef(null)
    const r3 = useRef(null)
    const r4 = useRef(null)
    const r5 = useRef(null)
    const r6 = useRef(null)
    const r7 = useRef(null)


    useScrollPosition(
        ({ prevPos, currPos }) => {
        console.log(currPos.x)
        console.log(currPos.y)
        },
        [],
        [wrapper],
        false,
        300,
    )

        


    




        let settingss = {
            dots: false,
            adaptiveHeight: true,
            infinite: false,
            arrows: false,
            centerMode: false,
            speed: 300,
            slidesToShow: 3,
            variableWidth: true, 
          }    



    return (    
        <>
        <div style={{
            height: "calc(100vh - 60px)",
            overflow: "y-scroll",
        }}
        ref={wrapper}>
            <div id="projekt-bread" className="projekt-aktywny projekt-header">
                <div className="lista-item">
                    <TransitionLink style={{
                        left: '0',
                        top: '0',
                        position:'absolute',
                    }} to="/projekty">
                        <img className="projekt-header-cofnij" src={back} />
                    </TransitionLink>
                    <h3>{title}</h3>
                    <div className="projekt-zajawka">
                        <img src={oko} />
                        <span className="przycisk">2014-2021</span>
                        <div className="projekt-zajawka-kategorie">
                            <span className="przycisk">badania i koncept</span>
                            <span className="przycisk">strategia wizualna</span>
                            <span className="przycisk">brand design</span>
                            <span className="przycisk">katalogi</span>
                            <span className="przycisk">akcydensy</span>
                            <span className="przycisk">web design</span>
                        </div>
                        
                        <p>Od 2014 roku odpowiadamy za warstwę wizualną  klubu, zarówno w obszarze systemu identyfikacji wizualnej oraz ewolucji cosezonowych platform komunikacji.</p>
                        <span 
                        style={{whiteSpace: 'nowrap', display: 'flex', alignItems: 'center'}}
                        className="przycisk"><img src={paluch} style={{marginRight: '10px'}}/>ZOBACZ PROJEKT</span>
                    </div>

                    { <div className="bread-dodatkowe slider-rozdzialy">
                    <div className="rozdzialy-maska"></div>
                    <Slick  ref={slider} className="rozdzial-slider" {...settingss}>
                    
                        <span onClick={
                            () => { r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(0);}}
                            className="przycisk">01. STRATEGIA MARKI</span>
                        <span onClick={
                            () => { r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(1);}}
                        
                        
                        className="przycisk">02. IDENTYFIKACJA MARKI</span>  
                        <span onClick={
                            () => { r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(2);}} 
                       className="przycisk">03. CORPORATE DESIGN</span>  
                        <span onClick={
                            () => { r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(3);}}
                        className="przycisk">04. PRINT</span>  
                        <span onClick={
                            () => { r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(4);}}
                        className="przycisk">05. DIGITAL DESIGN</span>  
                        <span onClick={
                            () => { r6.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(5);}}
                        
                        className="przycisk">06. WEB DESIGN</span>  
                        <span onClick={
                            () => { r7.current.scrollIntoView({ behavior: 'smooth', block: 'start' }); slider.current.slickGoTo(6);}} className="przycisk">07. FOTOGRAFIA</span>  
            
                        
                    </Slick>
                    <div className="rozdzialy-maska"></div>
                    </div>}

                </div>

            </div>


            <Seo title={title} description="Nasze przygoda z wrocławską siatkówką trwa nieprzerwanie od 2015 roku. W tym czasie przeprowadziliśmy redesign marki Volley Wrocław, stworzyliśmy zaplecze mediowe klubu, projektowaliśmy sprzęt sportowy, gadżety dla kibiców, elementy widowiska czy materiały sponsorskie. W ubiegłym roku opracowaliśmy nową  platformę komunikacji klubu, dedykowaną dla sezonu 2020." image={data.okladka.childImageSharp.fluid} />
            <div className="podstrona-wrapper projekt-padding">

            <Img style={{width: '100%'}} fluid={data.okladka.childImageSharp.fluid} />

            <Intro tytul="Wprowadzenie" tekst="Nasze przygoda z wrocławską siatkówką trwa nieprzerwanie od 2015 roku. W tym czasie przeprowadziliśmy redesign marki Volley Wrocław, stworzyliśmy zaplecze mediowe klubu, projektowaliśmy sprzęt sportowy, gadżety dla kibiców, elementy widowiska czy materiały sponsorskie. W ubiegłym roku opracowaliśmy nową  platformę komunikacji klubu, dedykowaną dla sezonu 2020." tekst1="W 2016 roku ustanowiliśmy rekord zwrotu z ekspozycji mediowej dla sponsorów klubu (AVE), generując największą wartość ekspozycji spośród wszystkich klubów w ORLEN Lidze." tekst2='Nasze działania prowadzone na rzecz klubu przedstawiane są jako „best practice” wobec zespołów marketingowych lig męskich i żeńskich w polskiej siatkówce. ' />

            <Img style={{width: '100%'}} fluid={data.okladka2.childImageSharp.fluid} />








            <div className={style.architektura}>
                <h3>ARCHITEKTURA PROJEKTU</h3>
                <div>

               
                        <div onClick={() => r1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   } data-rozdzial={1}>
                            <span className="przycisk">
                                01.STRATEGIA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={2}>
                            <span className="przycisk">
                                02. IDENTYFIKACJA MARKI
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r3.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }  data-rozdzial={3}>
                            <span className="przycisk">
                                03. CORPORATE DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>
                        
                        <div onClick={() => r4.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={4}>
                            <span className="przycisk">
                                04. PRINT
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r5.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={5}>
                            <span className="przycisk">
                                05. DIGITAL DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                        <div onClick={() => r6.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={6}>
                            <span className="przycisk">
                                06. WEB DESIGN
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>


                        <div onClick={() => r7.current.scrollIntoView({ behavior: 'smooth', block: 'start' })   }   data-rozdzial={7}>
                            <span className="przycisk">
                                07. FOTOGRAFIA
                            </span>
                            <img src={strzalka} />
                            <div className={style.architektura_acco}>
                                <img src={oko} />
                                <h6></h6>
                            </div>
                        </div>

                       


           
                </div>
            </div>
            </div>


            <InView  as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('0')}>
            <div ref={r1}><Strategia  /></div>
            </InView>

            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('1')}>
            <div ref={r2}><Identyfikacja /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('2')}>
            <div ref={r3}><Print  /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('3')}>
            <div ref={r4}><WebDesign /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('4')}>
            <div ref={r5}><Digital /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('5')}>
            <div ref={r6}><Social /></div>
            </InView>
            <InView as="div" threshold="0.35" onChange={(inView, entry) => slider.current.slickGoTo('6')}>
            <div ref={r7}><Fotografia /></div>
            </InView>
          

            <div className="podstrona-wrapper">
            <Ocena tekst='"Współpraca z Odra Studio to gwarancja profesjonalizmu, ale też olbrzymiej dawki kreatywności, dzięki niesamowitemu zespołowi specjalistów. Natomiast same działania Odra Studio na linii Red Bull x Maciej Janowski są stawiane przez nas jako best practice dla innych zawodników z naszego portfolio. Odra Studio wzorowo wywiązuje się z zleconych zadań dbając przy tym o najwyższą jakość usług i zachowanie unikalnej relacji i polecamy usługi wszystkim, którzy podobnie jak by cenią sobie współpracę na najwyższym poziomie."' firma="Athletes’ Manager, Red Bull" imie="Marek Krynicki" />

            <Slider  uslugi={true} />
            </div>

            <Inne omin="Maciej Janowski" />


            


            <Formularz />
            </div>
      
         
        </> 
    )
}