import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Komp_zdjecie from '../comp/kol_tekst'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
import TytulPunkty from '../comp/tytul_punkty'
import telebim from './telebim.mp4'


export default function Strategia() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/volley/2/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                },
                slider1: file(relativePath: { eq: "projekty/magic/2/slider1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider2: file(relativePath: { eq: "projekty/magic/2/slider2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                slider3: file(relativePath: { eq: "projekty/magic/2/slider3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp1: file(relativePath: { eq: "projekty/magic/2/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                komp2: file(relativePath: { eq: "projekty/magic/2/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek: file(relativePath: { eq: "projekty/magic/2/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                maciek2: file(relativePath: { eq: "projekty/magic/2/maciek2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
                brandbook: file(relativePath: { eq: "projekty/volley/2/brandbook@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                },
               
                    

               

            }
            `)

    


    const foto = [data.slider1.childImageSharp.fluid, data.slider2.childImageSharp.fluid, data.slider3.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="IDENTYFIKACJA SEZONU 20/21" invert={true} tekst="Każdy sezon to dla kibiców nowe otwarcie i szansa na walkę o najwyższe sportowe cele, spełnienie marzeń i nowe doświadczenia. Aby uczynić je wyjątkowymi, co roku odświeżamy platformę komunikacji klubu. Zobacz jak wyglądała ona w sezonie 2020/2021." nr="02." img={data.okladka.childImageSharp.fluid}>


      
        <TytulPunkty tekst="Głównym motywem graficznym Volley Wrocław są  ukośne linie symbolizujące „W” jak Wrocław, sylwetkę  zawodniczki w ataku oraz dynamikę  dyscypliny." tekst1="Dla zachowania spójności marki i jej cech charakterystycznych, bazowe elementy identyfikacji pozostają niezmienne, a dodatkowe działania realizowane są wokół nich, jako centralnych punktów wizerunku." tekst2="Wraz z nowymi sezonami ewoluuje sposób przestawienia elementów bazowych, sposób wykorzystania fotografii a także budowa layoutów. Dzięki temu klub zachowuje świeżość i buduje ekscytację wchodząc w nowe sezony rozgrywkowe." />  

        


        {/* <SliderDuzy foto={foto} /> */}



    

        <Intro tekst="Diagonale, kolor oraz typografia to niezmienne elementy systemu, które podlegają  ewolucji, by budować  poczucie rozwoju i świeżości, przychodzące wraz z kolejnymi sezonami." tekst1="Zaprezentowane realizacje dotyczą wyłącznie sezonu 2020/21. Aby poznać pełny proces ewolucji wizerunku klubu, zapraszamy do odwiedzenia mediów społecznościowych klubu."/>

        <video style={{width: '100%'}} autoPlay muted playsInline loop>
          <source src={telebim} type="video/mp4" />
        </video>

        <Komp_zdjecie img={data.brandbook.childImageSharp.fluid} tytul="Brandbook" tekst="Instrukcja użycia marki została opisana w formie brandbooka. Dzięki niemu pracownicy, partnerzy, czy agencje obsługujące mogą przyspieszyć proces działań i upewnić się, że marka rozwija się w jednym, ustalonym kierunku, wysyłając oczekiwane i spójne komunikaty wobec swoich interesariuszy."
        />
    

        

  
        
        </Rozdzial>
        
        </>
    )
}