import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
import SliderDuzy from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'
export default function Identyfikacja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/volley/3/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                slider_2: file(relativePath: { eq: "projekty/volley/3/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                slider_3: file(relativePath: { eq: "projekty/volley/3/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                slider_4: file(relativePath: { eq: "projekty/magic/3/slider4@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                }
                }

                okladka: file(relativePath: { eq: "projekty/volley/3/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full1: file(relativePath: { eq: "projekty/volley/3/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full2: file(relativePath: { eq: "projekty/volley/3/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                maciek: file(relativePath: { eq: "projekty/magic/3/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                maciek2: file(relativePath: { eq: "projekty/magic/3/maciek2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus1: file(relativePath: { eq: "projekty/magic/3/bus1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                bus2: file(relativePath: { eq: "projekty/magic/3/bus2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                

                komp1: file(relativePath: { eq: "projekty/magic/3/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp2: file(relativePath: { eq: "projekty/magic/3/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp3: file(relativePath: { eq: "projekty/magic/3/komp3@2x.jpg"}) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                komp4: file(relativePath: { eq: "projekty/magic/3/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp5: file(relativePath: { eq: "projekty/magic/3/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                plakat1: file(relativePath: { eq: "projekty/magic/3/plakat1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat2: file(relativePath: { eq: "projekty/magic/3/plakat2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                plakat3: file(relativePath: { eq: "projekty/magic/3/plakat3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

            }
            `)

    


 

    

    const foto3 = [data.slider_1.childImageSharp.fluid, data.slider_2.childImageSharp.fluid, data.slider_3.childImageSharp.fluid]
    
    return(
        <>

        <Rozdzial tytul="Corporate design" invert={false} tekst="Klub sportowy jak wszystkie inne podmioty gospodarcze prowadzą działalność biznesową a nawiązywanie relacji i budowanie pozytywnego wizerunku na poziomie współpracy biznesowej są kluczowe dla jego funkcjonowania. W ramach współpracy z Volley Wrocław tworzymy rozwiązania korporacyjne, w tym akcydensy biznesowe, prezentacje sponsorskie zarówno w formie spakietyzowanych ofert jak i dedykowanych materiałów tworzonych bezpośrednio pod potrzeby wskazanego biznesu. Ze względu na poufny charakter niektórych treści, prezentujemy okrojony zakres materiałów. Po więcej - zachęcamy do kontaktu z Klubem." nr="03." img={data.okladka.childImageSharp.fluid}>

        
         
        <TytulPunkty tekst="Materiały biurowe Volley Wrocław opracowane są  według klucza identyfikacji sezonu i jak ona podlegają  ewolucji. Nie mniej ich charakter jest bardziej stonowany 
i biznesowy." tekst1="W ramach współpracy odpowiadamy za tworzenie materiałów, przygotowanie do produkcji oraz bezpośredni kontakt z producentem - dzięki temu mamy gwarancję, że wszystko dociera do klienta w takiej formie, jaką zaplanowaliśmy." tekst2="W zależności od wymagań projektu, jesteśmy w stanie stosować wszelkie dostępne uszlachetnienia, indywidualnie dobierać najefektowniejsze surowce oraz metody druku. Wszystko po to aby Twój projekt był niepowtarzalny i realizował założone cele wizerunkowe." />


   

        <Img fluid={data.full1.childImageSharp.fluid} />
        

        <SliderDuzy foto={foto3} />
        <Intro tytul="Wprowadzenie" tekst="Diagonale, kolor oraz typografia to niezmienne elementy systemu, które podlegają  ewolucji, by budować  poczucie rozwoju i świeżości, przychodzące wraz z kolejnymi sezonami." />


        <Img fluid={data.full2.childImageSharp.fluid} />
        
        

        
        
        </Rozdzial>
        
        </>
    )
}