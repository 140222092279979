import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import KolTekst from '../comp/kol_tekst'
import Intro from '../comp/projekt_intro'
import Rozdzial from '../comp/rozdzial'
import Widoki from '../comp/widoki_projektu'
import Wyniki from '../comp/wyniki'
import strona from './proces_www.mp4'

export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/volley/6/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }

                postac: file(relativePath: { eq: "projekty/volley/6/postac@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                komp1: file(relativePath: { eq: "projekty/volley/6/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                full1: file(relativePath: { eq: "projekty/volley/6/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }
                komputer: file(relativePath: { eq: "projekty/volley/6/komputer@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                    tablet: file(relativePath: { eq: "projekty/volley/6/komputer/2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }
                        
                        mobile: file(relativePath: { eq: "projekty/volley/6/komputer@2x.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            }
                slider_1: file(relativePath: { eq: "projekty/magic/6/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    }
    
                    slider_2: file(relativePath: { eq: "projekty/magic/6/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fixed(width: 360, height: 360) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                    }
    
    
                    slider_3: file(relativePath: { eq: "projekty/magic/6/slider/3@2x.jpg" }) {
                        childImageSharp {
                            fixed(width: 360, height: 360) {
                                ...GatsbyImageSharpFixed
                            }
                    }
                    }
    
                    slider_4: file(relativePath: { eq: "projekty/magic/6/slider/4@2x.jpg" }) {
                        childImageSharp {
                            fixed(width: 360, height: 360) {
                                ...GatsbyImageSharpFixed
                            }
                    }
                    }

                    wyniki: file(relativePath: { eq: "projekty/magic/6/wyniki@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                    }
                    }
            }
            `)


    const foto = [data.slider_1.childImageSharp.fixed, data.slider_2.childImageSharp.fixed, data.slider_3.childImageSharp.fixed, data.slider_4.childImageSharp.fixed]



    return (
        <>

            <Rozdzial tytul="WEB DESIGN" invert={true} tekst="Dedykowana witryna internetowa została stworzona aby rozwijać cyfrową obecność klubu i relacje ze wszystkimi kluczowymi grupami interesariuszy. Od kibiców pierwszego zespołu, przez młode adeptki siatkówki i ich rodziny, do dziennikarzy sportowych i partnerów biznesowych. Strona wyposażona jest w kompletne informacje o rozgrywkach, zespołach, posiada panel aktualności a także sklep internetowy, z pośrednictwem którego kibice mogą wyposażyć się w gadżety meczowe i upominki klubowe. " nr="06." img={data.okladka.childImageSharp.fluid}>

                <Intro tekst="W strefie kibica organizowanej przez nas podczas Betard Speedway Grand Prix zjawiły się  setki kibiców, zachęcanych grami, spotkaniem z zawodnikami i gadżetami zaprojektowanymi na tą  okazję."/>

                <Widoki tekst="Zobacz jak wygląda obecna wersja strony www.volleywroclaw.pl na wszystkich najważniejszych urządzeniach. Po więcej odwiedź oficjalną stronę Klubu." img1={data.komputer.childImageSharp.fluid} 
                 img2={data.komputer.childImageSharp.fluid} 
                 img3={data.komputer.childImageSharp.fluid} />

                 <video style={{width: '100%', paddingTop: '50px' }} autoPlay muted playsInline loop>
                 <source src={strona} type="video/mp4" />
                 </video>

                <KolTekst img={data.komp1.childImageSharp.fluid} tekst="brak" tytul="brak" />

                <Img fluid={data.full1.childImageSharp.fluid} />

                <Intro tekst="Łącznie, w projekcie stworzyliśmy niemal sto niezależnych widoków dla każdej z rozdzielczości (desktop, tablet, mobile). Layout strony wynika bezpośrednio z systemu identyfikacji marki Volley Wrocław." tytul="Wprowadzenie" />

                <Wyniki
                postac={data.postac.childImageSharp.fluid}
                wynik1="60 tys" tekst1="wejść na stronę
                volleywroclaw.pl" wynik2="10 tys" tekst2="UŻYTKOWNIKÓWODWIEDZAJĄCYCH WITRYNĘ" wynik3="7 tys" tekst3="ODWIEDZIN STRONYSKLEPOWEJ KLUBU" />

                

            </Rozdzial>

        </>
    )
}