import { graphql, useStaticQuery } from "gatsby"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'
import sesja from './sesja.mp4'


export default function WebDesign() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/volley/7/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }

                slider_1: file(relativePath: { eq: "projekty/volley/7/slider2/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_2: file(relativePath: { eq: "projekty/volley/7/slider2/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_3: file(relativePath: { eq: "projekty/volley/7/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_4: file(relativePath: { eq: "projekty/volley/7/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_5: file(relativePath: { eq: "projekty/volley/7/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

                slider_6: file(relativePath: { eq: "projekty/volley/7/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                }
                }

             

                prezentacje: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }

                    prezentacje2: file(relativePath: { eq: "projekty/betard/7/prezentacje_kol2@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }

                    prezentacja: file(relativePath: { eq: "projekty/betard/7/prezentacja@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }


                    mockup: file(relativePath: { eq: "projekty/betard/7/mockup@2x.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 2000, quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        }    

                        mockup2: file(relativePath: { eq: "projekty/betard/7/mockup2@2x.jpg" }) {
                            childImageSharp {
                                fluid(maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                            }  


                            mockup3: file(relativePath: { eq: "projekty/betard/7/mockup3@2x.jpg" }) {
                                childImageSharp {
                                    fluid(maxWidth: 2000, quality: 100) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                                } 


            komp1: file(relativePath: { eq: "projekty/betard/7/komp1@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
                }  
                
                komp2: file(relativePath: { eq: "projekty/betard/7/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                    }  
}
            `)

            const foto = [data.slider_3.childImageSharp.fluid, data.slider_4.childImageSharp.fluid, data.slider_5.childImageSharp.fluid, data.slider_6.childImageSharp.fluid]
    const foto2 = [data.slider_1.childImageSharp.fluid, data.slider_2.childImageSharp.fluid, data.slider_1.childImageSharp.fluid]

   
    
    return(
        <>

        <Rozdzial tytul="FOTOGRAFIA" invert={false} tekst="Każdy klub sportowy wymaga kompleksowej obsługi fotograficznej. Nowy sezon rozgrywkowy wiąże się z potrzebą produkcji sesji profilowej całego zespołu, której efekt wykorzystywany jest później w większości materiałów graficznych. W ramach obsługi realizujemy także zdjęcia podczas meczów klubu oraz wydarzeń klubowych, takiech jak spotkania z kibicami, konferencje prasowe czy wewnętrzne aktywności zespołu. Ostatnim elementem działań fotograficznych jest fotografia produktowa, która wykorzystywana jest w mediach społecznościowych, na stronie internetowej oraz w drukowanych materiałach podczas sprzedaży stacjonarnej." nr="07." img={data.okladka.childImageSharp.fluid}>
        
         
   
        <TytulPunkty tekst="W ramach współpracy z klubem realizujemy sesje studyjne zawodniczek, fotografie z meczów domowych i wyjazdowych, a także towarzyszymy w roli fotografów podczas ważnych wydarzeń okolicznościowych. " tekst1="Zobacz jak wygląda obecna wersja strony www.volleywroclaw.pl na wszystkich najważniejszych urządzeniach. Po więcej odwiedź oficjalną stronę Klubu. " tekst2="Fotografia reportażowa
Zobacz jak wygląda obecna wersja strony www.volleywroclaw.pl na wszystkich najważniejszych urządzeniach. Po więcej odwiedź oficjalną stronę Klubu." />

<video style={{width: '100%', paddingTop: '50px' }} autoPlay muted playsInline loop>
                 <source src={sesja} type="video/mp4" />
                 </video>

        <Slider_d foto={foto} />

        <TytulPunkty tekst="W ciągu siedmiu lat współpracy z klubem zrealizowaliśmy 7 sportowych sesji studyjnych całego zespołu, obsłużyliśmy przeszło 100 meczów w roli oficjalnych fotografów ligowych oraz dziesiątki wydarzeń okołosportowych."  />

        <Slider_d foto={foto2} />
        

        </Rozdzial>

        
        
        </>
    )
}