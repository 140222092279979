import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Komp_kol from '../comp/komp_2kol_tekst_foto'
import Punkt from '../comp/punkt_flex'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'


export default function Ilustracja() {
    const data = useStaticQuery(graphql`
            query {
                slider_1: file(relativePath: { eq: "projekty/volley/4/slider/1@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                

                slider_2: file(relativePath: { eq: "projekty/volley/4/slider/2@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                slider_3: file(relativePath: { eq: "projekty/volley/4/slider/3@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                slider_4: file(relativePath: { eq: "projekty/volley/4/slider/4@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                okladka: file(relativePath: { eq: "projekty/volley/4/okladka@2x.jpg" }) {
                    childImageSharp {
                        fluid(maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                full1: file(relativePath: { eq: "projekty/volley/4/full1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full2: file(relativePath: { eq: "projekty/volley/4/full2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full3: file(relativePath: { eq: "projekty/volley/4/full3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full4: file(relativePath: { eq: "projekty/volley/4/full4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full5: file(relativePath: { eq: "projekty/volley/4/full5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                full6: file(relativePath: { eq: "projekty/volley/4/full6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                vp: file(relativePath: { eq: "projekty/betard/2/vp@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty: file(relativePath: { eq: "projekty/betard/4/skala_produkty@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                produkty_animacja: file(relativePath: { eq: "projekty/betard/4/produkty_animacja@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                przeswietlenia: file(relativePath: { eq: "projekty/betard/4/przeswietlenia@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja1: file(relativePath: { eq: "projekty/betard/4/ilustracja1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                ilustracja2: file(relativePath: { eq: "projekty/betard/4/ilustracja2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                tablet: file(relativePath: { eq: "projekty/magic/4/tablet@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komputer: file(relativePath: { eq: "projekty/magic/4/komputer@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                mobile: file(relativePath: { eq: "projekty/magic/4/mobile@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                
                komp1: file(relativePath: { eq: "projekty/volley/4/komp1@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp2: file(relativePath: { eq: "projekty/volley/4/komp2@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp3: file(relativePath: { eq: "projekty/volley/4/komp3@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp4: file(relativePath: { eq: "projekty/volley/4/komp4@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp5: file(relativePath: { eq: "projekty/volley/4/komp5@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                komp6: file(relativePath: { eq: "projekty/volley/4/komp6@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                postac: file(relativePath: { eq: "projekty/magic/4/postac@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                }

                

            }
            `)

    


            const foto = [data.slider_1.childImageSharp.fluid, data.slider_2.childImageSharp.fluid, data.slider_3.childImageSharp.fluid, data.slider_4.childImageSharp.fluid]
    
    
    return(
        <>

        <Rozdzial tytul="PRINT" invert={true} tekst="Tworzymy wszystkie kluczowe rozwiązania drukowane, wykorzystywane przez Volley Wrocław. Są to zarówno materiały reklamowe, elementy widowiska, sprzęt sportowy a także rozwiązania niestandardowe, takie jak siatki wielkoformatowe, projekty brandingu tramwajów miejskich czy pojazdów służbowych biura i zawodniczek.  " nr="04." img={data.okladka.childImageSharp.fluid}>
        
        <TytulPunkty tekst="Cechą  charakterystyczną  klubów sportowych jest ogromna ilość zróżnicowanych druków występujących na często bardzo niestandardowych polach ekspozycji." tekst1="W ramach współpracy odpowiadamy za tworzenie materiałów, przygotowanie do produkcji oraz  za bezpośredni kontakt z producentem." tekst2="Współpracując z Volley Wrocław od 2015 roku stworzyliśmy tysiące indywidualnych materiałów drukowanych o zróżnicowanym poziomie skomplikowania."/>

        
    
        <Img fluid={data.full1.childImageSharp.fluid} />


        <Komp_kol tytul1="DRUKI MECZOWE" tekst1="Druki meczowe to dziesiątki różnych nośników związanych z całym procesem obsługi widowiska. Znajdują się tu plakaty, bilety, karnety, identyfikatory dla staffu, informatory dla mediów, przenośne elementy wayfindingu, stoiska sklepowe czy nośniki reklamowe takie jak naklejki na boisku czy bandy sponsorskie." img1={data.komp1.childImageSharp.fluid} img2={data.komp2.childImageSharp.fluid} />

        <Komp_kol  img1={data.komp3.childImageSharp.fluid} img2={data.komp4.childImageSharp.fluid} />


        <TytulPunkty tekst="Cechą  charakterystyczną  klubów sportowych jest ogromna ilość zróżnicowanych druków występujących na często bardzo niestandardowych polach ekspozycji."/>

        <Img fluid={data.full2.childImageSharp.fluid} />

        <Punkt tytul="Stoisko sklepowe" tekst="Mobilne stoisko sklepowe stworzone zostało do sprzedaży klubowych gadżetów podczas meczów klubu w hali Orbita, a także podczas rozgrywek młodzieżowych na turniejach rozgrywanych w różnych halach na Dolnym Śląsku." />

        <Img fluid={data.full3.childImageSharp.fluid} />

        <Komp_kol  img1={data.komp5.childImageSharp.fluid} img2={data.komp6.childImageSharp.fluid} />

        <Punkt tytul="Wayfinding" tekst="Mobilne materiały wayfindingowe pomagają kibicom odnaleźć się w przestrzeni hali Orbita. Opracowaliśmy kompleksowy system nawigacji, rozciągający się na nośniki stojące, ustawiane przed halą oraz w głównych ciągach komunikacyjnych hali, wiszące - wieszane w przestrzeni hali i trybun oraz siatki z wizerunkami zawodniczek, tworzące atmosferę widowiska i poczucie przywiązania do klubu." />

        <Img fluid={data.full4.childImageSharp.fluid} />

        <Img fluid={data.full5.childImageSharp.fluid} />

        <TytulPunkty tekst="System wayfindingu jest mobilny, stworzony tak, by móc być rozstawiony w szybkim tempie, w dzień meczowy w hali Orbita."/>

        <Img fluid={data.full6.childImageSharp.fluid} />

        <Punkt tytul="GADŻETY DRUKOWANE" tekst="Stworzone przez nas drukowane gadżety klubowe to m.in. odzież dla kibiców, kalendarze, fankarty zawodniczek, zestawy naklejek i wiele wiele innych. Baza gadżetów rozwijana jest z każdym sezonem i stanowi asortyment zarówno w sklepie online jak i na stworzonym przez nas mobilnym stanowisku sprzedażowym prowadzonym przez klub w Hali Orbita." />

        <Slider_d foto={foto} />

        
        
        </Rozdzial>
        
        </>
    )
}