import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from 'react'
import Rozdzial from '../comp/rozdzial'
import Slider_d from '../comp/slider_duzy'
import TytulPunkty from '../comp/tytul_punkty'

const informacje = [
    {
        tytul: "DESK RESEARCH",
        opis: "Poznanie branży, analiza raportów branżowych, mediów branżowych, audyt obecności w mediach marki klienta i działań konkurencji."
    },
    {
        tytul: "ANALIZA DANYCH ZASTANYCH",
        opis: "Analiza danych dostarczonych przez klienta - poznanie produktów, wyników sprzedaży, efektywności działań mediowych, analiza modelu biznesowego. Przekrojowe poznanie dynamiki funkcjonowania biznesu."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY WEWNĘTRZNE",
        opis: "Rozmowy bezpośrednie z ekspertami firmy Betard - członkami Zarządu, szefami, działów produktowych, BOK, HR i marketingu. Uzupełnienie wiedzy o modelu firmy, poszerzenie wiedzy o produktach, relacjach z klientami, o ich potrzebach i problemach."
    },
    {
        tytul: "POGŁĘBIONE WYWIADY ZEWNĘTRZNE",
        opis: "Rozmowy z przedstawicielami grup klientów firmy. Poznanie potrzeb, oczekiwanych korzyści, problemów, procesów, i postrzegania marki. Wywiady indywidualne, transkrypcja, kodowanie, analiza."
    },
    {
        tytul: "BADANIA UŻYTECZNOŚCI",
        opis: "Badania użyteczności strony internetowej marki. Analiza user flows, weryfikacja widoczności i dostępności elementów. Opracowanie rekomendacji dla wdrożenia nowej witryny www.teamjanowski.com"
    },
]

export default function Research() {
    const data = useStaticQuery(graphql`
            query {
                okladka: file(relativePath: { eq: "projekty/volley/1/okladka@2x.jpg" }) {
                childImageSharp {
                    fluid( maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
                },
                maciek: file(relativePath: { eq: "projekty/magic/1/maciek@2x.jpg" }) {
                    childImageSharp {
                        fluid( maxWidth: 2000, quality: 100) {
                        ...GatsbyImageSharpFluid
                        }
                    }
                    }
                    full1: file(relativePath: { eq: "projekty/volley/1/full1@2x.jpg" }) {
                        childImageSharp {
                            fluid( maxWidth: 2000, quality: 100) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                        }


                        slider_1: file(relativePath: { eq: "projekty/volley/1/slider/1@2x.jpg" }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                        }
                        }
        
                        slider_2: file(relativePath: { eq: "projekty/volley/1/slider/2@2x.jpg" }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                        }
                        }
                        slider_3: file(relativePath: { eq: "projekty/volley/1/slider/3@2x.jpg" }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                        }
                        }
                        slider_4: file(relativePath: { eq: "projekty/volley/1/slider/4@2x.jpg" }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                        }
                        }
                        slider_5: file(relativePath: { eq: "projekty/volley/1/slider/5@2x.jpg" }) {
                            childImageSharp {
                                fluid( maxWidth: 2000, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                    }
                        }
                        }


                
            }
            `)


            const foto = [data.slider_1.childImageSharp.fluid, data.slider_2.childImageSharp.fluid, data.slider_3.childImageSharp.fluid, data.slider_4.childImageSharp.fluid, data.slider_5.childImageSharp.fluid]
     
    return(
        <>

        <Rozdzial tytul="STRATEGIA
MARKI" tekst="Pomimo zrealizowanego w 2014 roku redesignu marki, który wynikał z transformacji klubu z Gwardii Wrocław do nowego podmiotu wspieranego przez Grupę Impel, marka jako taka nie została sformułowana i opisana. W związku z tym klub poszukiwał własnej tożsamości a naszą rolą było określenie cech osobowości i zarządzanie wizerunkiem na wielu polach ekspozycji. - od obecności cyfrowej do widowisk sportowych. Zwieńczeniem naszej pracy był sezon 2016-2017, w którym klub osiągnął najlepsze wyniki oglądalności w lidze i wygenerował rekordowy zwrot dla sponsorów w postaci wartości ich ekspozycji w mediach." nr="01" img={data.okladka.childImageSharp.fluid}>
        <TytulPunkty tekst="W 2018 roku wspieraliśmy proces transformacji wizerunku klubu, który z marki Impel Wrocław przekształcił się  w niezależny podmiot – Volley Wrocław."/>
        <Img fluid={data.full1.childImageSharp.fluid} />
        <TytulPunkty tekst="Od 2015 roku odpowiadamy za warstwę wizualną  klubu, zarówno w obszarze systemu identyfikacji wizualnej oraz ewolucji cosezonowych platform komunikacji."/>
        <Slider_d foto={foto} />
       
        </Rozdzial>
        
        </>
    )
}